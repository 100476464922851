/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
#primary-nav .level-1 {
  width: 20%;
  padding: 8px 0;
  /*
    &:nth-child(4) {
    	width: 22.6%;
	}
	*/ }

.pane-wcm-cta .btn {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 30px; }
  .pane-wcm-cta .btn:before {
    line-height: 2.5; }

.block-menu-block .content {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px; }

.footer-nav {
  margin-bottom: 20px; }

.information-column {
  overflow: hidden; }

.pane-profiles-panel-pane-3, .pane-profiles-panel-pane-4 {
  display: block;
  float: left;
  width: 100%; }
  .pane-profiles-panel-pane-3 ul li, .pane-profiles-panel-pane-4 ul li {
    float: left;
    width: 100%; }
  .pane-profiles-panel-pane-3 .views-widget-filter-field_person_type_taxonomy_tid, .pane-profiles-panel-pane-4 .views-widget-filter-field_person_type_taxonomy_tid {
    display: block;
    width: 100%;
    min-height: 59px;
    border-bottom: 1px solid #edeced;
    border-top: 4px solid #d7d7d7;
    background-color: #f6f6f6;
    padding: 0;
    margin-top: 10px; }
    .pane-profiles-panel-pane-3 .views-widget-filter-field_person_type_taxonomy_tid a, .pane-profiles-panel-pane-4 .views-widget-filter-field_person_type_taxonomy_tid a {
      font-size: 22px;
      display: block;
      float: left;
      height: 59px;
      line-height: 59px;
      padding: 0 30px;
      color: #d8d8d8; }
      .pane-profiles-panel-pane-3 .views-widget-filter-field_person_type_taxonomy_tid a:after, .pane-profiles-panel-pane-4 .views-widget-filter-field_person_type_taxonomy_tid a:after {
        display: none; }
    .pane-profiles-panel-pane-3 .views-widget-filter-field_person_type_taxonomy_tid #edit-field-person-type-taxonomy-tid-15 a.active, .pane-profiles-panel-pane-4 .views-widget-filter-field_person_type_taxonomy_tid #edit-field-person-type-taxonomy-tid-15 a.active {
      border-left: 1px solid #edeced; }
    .pane-profiles-panel-pane-3 .views-widget-filter-field_person_type_taxonomy_tid .active, .pane-profiles-panel-pane-4 .views-widget-filter-field_person_type_taxonomy_tid .active {
      color: #000000;
      background-color: #fff;
      border-right: 1px solid #edeced;
      height: 60px;
      line-height: 60px;
      top: 1px;
      position: relative; }
  .pane-profiles-panel-pane-3 .views-row, .pane-profiles-panel-pane-4 .views-row {
    border-bottom: 1px solid #d7d7d7;
    float: left;
    width: 100%; }
    .pane-profiles-panel-pane-3 .views-row:last-child, .pane-profiles-panel-pane-4 .views-row:last-child {
      border-bottom: 0px; }
  .pane-profiles-panel-pane-3 .profile__wrap, .pane-profiles-panel-pane-4 .profile__wrap {
    float: left;
    margin-top: 30px;
    padding-bottom: 30px; }
  .pane-profiles-panel-pane-3 .views-field-nothing h4, .pane-profiles-panel-pane-4 .views-field-nothing h4 {
    color: #000000;
    margin-top: 33px; }
    @media screen and (min-width: 768px) {
      .pane-profiles-panel-pane-3 .views-field-nothing h4, .pane-profiles-panel-pane-4 .views-field-nothing h4 {
        margin-top: 33px; } }
  .pane-profiles-panel-pane-3 .profile__professional-title ul, .pane-profiles-panel-pane-4 .profile__professional-title ul {
    float: left; }
    @media screen and (min-width: 768px) {
      .pane-profiles-panel-pane-3 .profile__professional-title ul, .pane-profiles-panel-pane-4 .profile__professional-title ul {
        width: 75%; } }
    @media screen and (min-width: 1200px) {
      .pane-profiles-panel-pane-3 .profile__professional-title ul, .pane-profiles-panel-pane-4 .profile__professional-title ul {
        width: 80%; } }
  .pane-profiles-panel-pane-3 .views-field-path, .pane-profiles-panel-pane-4 .views-field-path {
    float: left; }
    @media screen and (min-width: 768px) {
      .pane-profiles-panel-pane-3 .views-field-path, .pane-profiles-panel-pane-4 .views-field-path {
        width: 75%; } }
    @media screen and (min-width: 1200px) {
      .pane-profiles-panel-pane-3 .views-field-path, .pane-profiles-panel-pane-4 .views-field-path {
        width: 80%; } }
  .pane-profiles-panel-pane-3 .element--show-hide, .pane-profiles-panel-pane-4 .element--show-hide {
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #dddddd; }
    @media screen and (min-width: 768px) {
      .pane-profiles-panel-pane-3 .element--show-hide, .pane-profiles-panel-pane-4 .element--show-hide {
        width: 75%; } }
    @media screen and (min-width: 1200px) {
      .pane-profiles-panel-pane-3 .element--show-hide, .pane-profiles-panel-pane-4 .element--show-hide {
        width: 80%; } }
  .pane-profiles-panel-pane-3 .element__toggle, .pane-profiles-panel-pane-4 .element__toggle {
    display: block;
    width: 100%;
    float: none; }
  .pane-profiles-panel-pane-3 .profile__image, .pane-profiles-panel-pane-4 .profile__image {
    margin: 0 20px 30px 0;
    float: left; }
  .pane-profiles-panel-pane-3 .profile__overlay, .pane-profiles-panel-pane-4 .profile__overlay {
    float: left;
    min-height: 217px; }
  .pane-profiles-panel-pane-3 a.btn, .pane-profiles-panel-pane-4 a.btn {
    clear: both;
    display: block;
    max-width: 155px;
    border-radius: 8px;
    padding: 5px 8px;
    font-size: 12px;
    margin-top: 20px; }

.profile__display-name {
  font-weight: bold; }

.pane-node-field-professional-title h3 {
  margin-top: 0px; }

.pane-node-field-vivo-link {
  margin: 20px auto;
  display: block;
  width: 150px; }

.btn-primary a {
  color: #fff; }

.pane-node-field-profile-image {
  float: right;
  margin: 0 0 20px 20px; }

.pane-news-panel-pane-1 .view-content, .pane-news-panel-pane-8 .view-content {
  margin-top: 20px;
  float: left; }

.pane-news-panel-pane-1 .view-teaser, .pane-news-panel-pane-8 .view-teaser {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px; }

.pane-news-panel-pane-1 h3, .pane-news-panel-pane-8 h3 {
  margin-top: 0; }

.pane-news-panel-pane-1 .teaser-image img, .pane-news-panel-pane-8 .teaser-image img {
  float: left;
  margin: 0 20px 10px 0; }

.pane-news-panel-pane-1 .teaser-image figcaption, .pane-news-panel-pane-8 .teaser-image figcaption {
  float: none;
  width: auto;
  text-align: left;
  margin-bottom: 10px; }

.form-item label {
  display: block; }

@media screen and (min-width: 992px) {
  .webform-component--information-of-the-deceased span.select2 {
    min-width: 544px; } }

div.sliderfield-text {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab; }
  div.sliderfield-text:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing; }

.brand__lockup img {
  max-height: 84px; }

video {
  border-radius: 0 !important; }

.radio + .radio, .checkbox + .checkbox {
  margin-top: 0px; }

.select2-container {
  min-width: 200px; }

.footer-contact__map-link a:after {
  display: none !important; }

.pane-directives-panel-pane-2 .directive, .pane-directives-panel-pane-3 .directive {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 10px; }
  .pane-directives-panel-pane-2 .directive .directive__download, .pane-directives-panel-pane-3 .directive .directive__download {
    display: block;
    clear: both; }
    .pane-directives-panel-pane-2 .directive .directive__download a, .pane-directives-panel-pane-3 .directive .directive__download a {
      background-size: 15px 15px;
      background-repeat: no-repeat;
      padding-left: 20px;
      background-position-y: 3px;
      background-position-x: 3px; }
    .pane-directives-panel-pane-2 .directive .directive__download a.download-file, .pane-directives-panel-pane-3 .directive .directive__download a.download-file {
      background-image: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/download.svg); }
    .pane-directives-panel-pane-2 .directive .directive__download a.view-file, .pane-directives-panel-pane-3 .directive .directive__download a.view-file {
      background-image: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/policies.svg); }

.pane-research-panel-pane-3 .research, .pane-research-panel-pane-2 .research, .pane-research-panel-pane-1 .research {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 10px; }

.pane-node-field-principal-investigator .field-label, .pane-node-field-sponsored-by .field-label, .pane-node-field-research-description .field-label {
  font-weight: bold;
  display: inline; }

.pane-node-field-research-type .field-label {
  font-weight: bold;
  display: inline;
  color: #000000;
  font-family: sans-serif , 'Open-Sans';
  font-size: 15px; }

.pane-node-field-news-category h3 {
  font-weight: bold;
  display: inline;
  color: #000000;
  font-family: sans-serif , 'Open-Sans';
  font-size: 15px; }

.webform-client-form-7 .panel-title {
  margin-bottom: 20px; }

.webform-client-form-7 .panel {
  background-color: #fff;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 0px;
  margin-top: 0px; }

.webform-client-form-7 .form-item {
  margin-bottom: 40px; }

.webform-client-form-7 .panel-body {
  padding: 0; }

.webform-client-form-7 .panel-default > .panel-heading {
  color: #b31b1b;
  font-size: 16px;
  background-color: #fff;
  border: none;
  font-weight: 600;
  padding-left: 0; }

.webform-client-form-7 .form-item label {
  font-size: 15px; }

.webform-client-form-7 .webform-component {
  float: left;
  width: 100%; }

.webform-client-form-7 label {
  padding: 0;
  margin: 0; }

.webform-client-form-7 .form-item.radio {
  float: left;
  width: 20%;
  text-align: center; }
  .webform-client-form-7 .form-item.radio input {
    display: block;
    margin: 0 auto 5px;
    position: relative; }

.webform-client-form-7 .form-checkboxes .form-item, .webform-client-form-7 .form-radios .form-item {
  margin: 18px 0 32px 0; }

.webform-client-form-7 .webform-submit {
  border-radius: 10px;
  padding: 5px 25px;
  font-size: 12px; }

.grief-cta {
  margin-top: 65px;
  margin-bottom: 40px;
  width: 100%;
  border-top: 1px solid #cf4520;
  border-bottom: 1px solid #cf4520;
  padding: 40px 0 40px 0; }
  .grief-cta svg {
    display: none;
    width: 45px;
    height: 45px;
    float: left;
    margin: 7px 25px 30px 15px;
    fill: #e7751d;
    color: #cf4520; }
    @media screen and (min-width: 768px) {
      .grief-cta svg {
        display: block; } }
  .grief-cta a {
    color: #000000; }
    .grief-cta a:after {
      content: '\e80d';
      color: #ffc72c;
      padding-left: 10px;
      font-size: 60%;
      vertical-align: top;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      text-decoration: none;
      line-height: 2.75; }

.respgcta a {
  display: block;
  padding: 0 20px; }

.ctools-collapsible-processed .ctools-collapsible-handle h2 {
  position: relative;
  font-family: "Open Sans",sans-serif;
  font-size: 13px;
  line-height: 18px;
  color: #cf4520;
  border: 1px solid #dddddd;
  border-width: 1px 0 0;
  cursor: pointer;
  margin: 0;
  padding: 15px 55px 15px 10px;
  font-weight: 600; }

.ctools-collapsible-processed .ctools-collapsible-handle h2:after {
  content: '-';
  width: 50px;
  display: block;
  position: absolute;
  top: 15px;
  right: 0;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  border-left: 3px solid #dddddd; }

.ctools-collapsible-processed.ctools-collapsed .ctools-collapsible-handle h2:after {
  content: '+'; }

.ctools-collapsible-container .ctools-toggle {
  display: none; }

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }
