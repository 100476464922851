#primary-nav .level-1 {
    width: 20%;
    padding: 8px 0;
    /*
    &:nth-child(4) {
    	width: 22.6%;
	}
	*/
}

.pane-wcm-cta{
	.btn{
		padding-top: 10px;
		padding-bottom: 10px;
		margin-bottom: 30px;
		&:before{
			line-height: 2.5;
		}
	}
}

.block-menu-block .content {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
}

.footer-nav{
	margin-bottom: 20px;
}

//Faculty pages...

.information-column{
	overflow: hidden;
}

.pane-profiles-panel-pane-3, .pane-profiles-panel-pane-4{
	display: block;
	float: left;
	width: 100%;
	ul li{
		float: left;
		width: 100%;
	}
	.views-widget-filter-field_person_type_taxonomy_tid{
        display: block;
        width: 100%;
        min-height: 59px;
        border-bottom: 1px solid #edeced;
        border-top: 4px solid #d7d7d7;
        background-color: #f6f6f6;
        padding: 0;
        margin-top: 10px;
        a{
        	font-size: 22px;
        	display: block;
			float: left;
			height: 59px;
			line-height: 59px;
			padding: 0 30px;
			color:	#d8d8d8;
			&:after{
				display: none;
			}
        }
        #edit-field-person-type-taxonomy-tid-15 a.active{
        	border-left: 1px solid #edeced;
        }
        .active{
			color: $wcm-black;
			background-color: $wcm-white;
			border-right: 1px solid #edeced;
			height: 60px;
			line-height: 60px;
			top: 1px;
			position: relative;
		}
    }
	.views-row{
		border-bottom: 1px solid #d7d7d7;
		float: left;
		width: 100%;
		&:last-child{
			border-bottom: 0px;
		}
	}
	.profile__wrap{
		float: left;
		margin-top: 30px;
		padding-bottom: 30px;
	}
	.views-field-nothing{
		//float: left;
		// width: 100%;
		// @include breakpoint($sm){
		// 	width: 75%;
		// }
		// @include breakpoint($lg){
		// 	width: 80%;
		// }
		h4{
			color: $wcm-black;
			margin-top: 33px;
			@include breakpoint($sm){
				margin-top: 33px;
			}
		}
	}
	.profile__professional-title{
		ul{
			float: left;
			@include breakpoint($sm){
				width: 75%;
			}
			@include breakpoint($lg){
				width: 80%;
			}
		}
	}
	.views-field-path{
		float: left;
		@include breakpoint($sm){
			width: 75%;
		}
		@include breakpoint($lg){
			width: 80%;
		}
	}

	.element--show-hide{
		float: left;
		width: 100%;
		margin-top: 20px;
		margin-bottom: 10px;
		@include breakpoint($sm){
			width: 75%;
		}
		@include breakpoint($lg){
			width: 80%;
		}
		border-bottom: 1px solid $wcm-border-gray;
	}

	.element__toggle{
		display: block;
		width: 100%;
		float: none;
		
	}

	.profile__image{
		margin: 0 20px 30px 0;
		float: left;

	}
	.profile__overlay{
		float: left;
		min-height: 217px;
		
	}
	a.btn{
		clear: both;
		display: block;
		max-width: 155px;
		border-radius: 8px;
		padding: 5px 8px;
		font-size: 12px;
	    margin-top: 20px;
	}
}

.profile__display-name{
	font-weight: bold;
}

//Profile node style
.pane-node-field-professional-title{
	h3{
		margin-top: 0px;
	}
}
.pane-node-field-vivo-link{
	margin: 20px auto;
	display: block;
	width: 150px;
}

.btn-primary{
	a{
		color: $wcm-white;
	}
}

.pane-node-field-profile-image{
	float: right;
	margin: 0 0 20px 20px;
}

//News page pane
.pane-news-panel-pane-1, .pane-news-panel-pane-8{
	.view-content{
		margin-top: 20px;
		float: left;
	}
	.view-teaser{
		border-bottom: 1px solid #ccc;
		margin-bottom: 20px;
	}
	h3{
		margin-top: 0;
	}
	.teaser-image{
		img{
			float: left;
			margin: 0 20px 10px 0;
		}
		figcaption{
			float: none;
			width: auto;
			text-align: left;
			margin-bottom: 10px;
		}
	}
}

.form-item label{
	display: block;
}

.webform-component--information-of-the-deceased{
	span.select2{
		@include breakpoint($md){
			min-width: 544px;
		}
	}
}

div.sliderfield-text{
	cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    &:active{
    	cursor: grabbing;
    	cursor: -moz-grabbing;
    	cursor: -webkit-grabbing;
    }
}

.brand__lockup{
	img{
		max-height: 84px;
	}
}

video{
	border-radius: 0!important;
}

.radio+.radio, .checkbox+.checkbox {
    margin-top: 0px;
}

.select2-container {
	min-width: 200px;
}

.footer-contact__map-link{
	a{
		&:after{
			display: none!important;
		}
	}
}

.pane-directives-panel-pane-2, .pane-directives-panel-pane-3{
	.directive{
		border-bottom: 1px solid $wcm-border-gray;
		padding-bottom: 10px;
		.directive__download{
			display: block;
			clear: both;
			a{
				background-size: 15px 15px;
    			background-repeat: no-repeat;
    			padding-left: 20px;
    			background-position-y: 3px;
    			background-position-x: 3px;
			}
				
			a.download-file{
				background-image: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/download.svg);
			}
			a.view-file{
				background-image: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/policies.svg);
			}
			
		}
	}
}

.pane-research-panel-pane-3, .pane-research-panel-pane-2, .pane-research-panel-pane-1{
	.research{
		border-bottom: 1px solid $wcm-border-gray;
		padding-bottom: 10px;
	}
}

.pane-node-field-principal-investigator, .pane-node-field-sponsored-by, .pane-node-field-research-description{
	.field-label{
		font-weight: bold;
		display: inline;
	}
}

.pane-node-field-research-type{
	.field-label{
		font-weight: bold;
		display: inline;
		color: $wcm-black;
		font-family: sans-serif , 'Open-Sans';
		font-size: 15px;
	}
}

.pane-node-field-news-category{
	h3{
		font-weight: bold;
		display: inline;
		color: $wcm-black;
		font-family: sans-serif , 'Open-Sans';
		font-size: 15px;
	}
}



.webform-client-form-7{
	.panel-title{
		margin-bottom: 20px;
	}

	.panel{
	    background-color: $wcm-white;
	    border: none;
	    border-radius: 0;
	    -webkit-box-shadow: none;
	    box-shadow: none;
	    margin-bottom: 0px;
	    margin-top: 0px;
	}

	.form-item {
	    margin-bottom: 40px;
	}

	.panel-body{
		padding: 0;
	}
	.panel-default>.panel-heading {
	    color: $wcm-red;
	    font-size: 16px;
	    background-color: $wcm-white;
	    border: none;
	    font-weight: 600;
	    padding-left: 0;
	}

	.form-item label {
    	font-size: 15px;
	}

	.webform-component{
		float: left;
		width: 100%;
	}
	label{
		padding: 0;
		margin: 0;
	}
	.form-item.radio{
		float: left;
		width: 20%;
		text-align: center;
		input{
			display: block;
    		margin: 0 auto 5px;
    		position: relative;
		}
	}
	.form-checkboxes .form-item, .form-radios .form-item {
    	margin: 18px 0 32px 0; 
	}
	.webform-submit{
		border-radius: 10px;
		padding: 5px 25px;
		font-size: 12px;
	}
}

.grief-cta{
	margin-top: 65px;
	margin-bottom: 40px;
	width: 100%;
	border-top: 1px solid $wcm-dark-orange;
	border-bottom: 1px solid $wcm-dark-orange;
	padding: 40px 0 40px 0;
	svg{
		display: none;
		width: 45px;
		height: 45px;
		float: left;
		margin: 7px 25px 30px 15px;
		fill: $wcm-bright-orange;
		color: $wcm-dark-orange;
		@include breakpoint($sm){
			display: block;
		}
	}
	a{
		color: $wcm-black;
		&:after{
        content: '\e80d';
        color: $wcm-yellow;
        padding-left: 10px;
        font-size: 60%;
        vertical-align: top;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        text-decoration: none;
        line-height: 2.75;
      }
	}
}

.respgcta{
	a{
		display: block;
		padding: 0 20px;
	}
}

.ctools-collapsible-processed .ctools-collapsible-handle h2 {
    position: relative;
    font-family: "Open Sans",sans-serif;
    font-size: 13px;
    line-height: 18px;
    color: $wcm-dark-orange;
    border: 1px solid $wcm-border-gray;
    border-width: 1px 0 0;
    cursor: pointer;
    margin: 0;
    padding: 15px 55px 15px 10px;
    font-weight: 600;
}


.ctools-collapsible-processed .ctools-collapsible-handle h2:after {
    content: '-';
    width: 50px;
    display: block;
    position: absolute;
    top: 15px;
    right: 0;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    border-left: 3px solid $wcm-border-gray;
}

.ctools-collapsible-processed.ctools-collapsed .ctools-collapsible-handle h2:after {
    content: '+';
}

.ctools-collapsible-container .ctools-toggle {display:none;}

